<template>
    <div class="js-entry o-entry c-html-entry">
        <!-- <iframe class="c-html-entry__iframe" v-if="entryData.Content.Index" :src="$xp.settings.baseUrl + 'xpedeo/' + entryData.Content.Index"></iframe>
        <iframe class="c-html-entry__iframe" v-else-if="entryData.Content.Url" :src="$xp.settings.baseUrl +'pda/'+ entryData.Content.Url"></iframe> -->
        <!-- <div v-if="srcUrl !== ''">
        <iframe class="c-html-entry__iframe" :src="srcUrl"></iframe>
        </div> -->
    </div>
</template>

<script>
// import { handleExternalUrl } from '@/util/router-guards'
import { handleExternalUrl, entryMixin } from '@xpedeo/core'
// import { } from '@xpedeo/core'

export default {
  mixins: [entryMixin],
  data () {
    return {
      srcUrl: '',
      entryPath: '',
      localIos: false
    }
  },
  async mounted () {
    // this.iframeElement = document.createElement('iframe')
    console.log('entrydata', this.entryData)
    window.addEventListener('message', this.onMessage, false)
    let currentUrl = ''
    if (this.entryData.Content.Index) {
      currentUrl = this.$xp.settings.baseUrl + 'xpedeo/' + this.entryData.Content.Index
    } else {
      currentUrl = this.$xp.settings.baseUrl + 'pda/' + this.entryData.Content.Url
    }
    if (this.$xp.device.platform === 'browser') {
      this.srcUrl = currentUrl
      this.addEntryToDocument()
    } else {
      if (this.$xp.device.platform === 'ios') {
        let remappedIosUrl = null
        if (window.XpedeoPreloader) {
          // window.XpedeoPreloader.getRemappedUrl(
          //   currentUrl,
          //   (iosUrl) => {
          //     console.log('ios url', iosUrl)
          //   },
          //   (errMsg) => {
          //     console.log(errMsg)
          //   })
          remappedIosUrl = await this.getRemappedIosUrl(currentUrl)
          // console.log('ios url', iosUrl)
          // remappedUrl = window.XpedeoPreloader.convertProxyUrl(remappedUrl)
          // console.log('converted url', remappedUrl)
        }
        if (remappedIosUrl !== null) {
          this.srcUrl = currentUrl
          console.log('this.srcUrl', this.srcUrl)
          const decodedUri = decodeURIComponent(this.srcUrl)
          console.log('decoded', decodedUri)
          // const splitted = this.srcUrl.split('xpedeo')
          const splitted = decodedUri.split('xpedeo/Binaries')
          console.log('splitted', splitted)
          this.entryPath = 'xpedeo/Binaries' + splitted[1].substring(0, splitted[1].indexOf('index'))
          const finalUrl = 'http://localhost:6363/xpedeo' + splitted[1]
          // const finalUrl = 'http://htmlentry:2578/xpedeo' + splitted[1]
          // this.iframeElement.src = finalUrl
          // this.srcUrl = finalUrl
          this.srcUrl = 'index.html'
          console.log('final htmlentry url ', finalUrl)
          this.localIos = true
          this.addEntryToDocument()
        } else {
          this.srcUrl = currentUrl
          this.addEntryToDocument()
        }
        // console.log('this.srcUrl', this.srcUrl)
        // const decodedUri = decodeURIComponent(this.srcUrl)
        // console.log('decoded', decodedUri)
        // // const splitted = this.srcUrl.split('xpedeo')
        // const splitted = decodedUri.split('xpedeo/Binaries')
        // console.log('splitted', splitted)
        // this.entryPath = 'xpedeo/Binaries' + splitted[1].substring(0, splitted[1].indexOf('index'))
        // const finalUrl = 'http://localhost:6363/xpedeo' + splitted[1]
        // // const finalUrl = 'http://htmlentry:2578/xpedeo' + splitted[1]
        // // this.iframeElement.src = finalUrl
        // // this.srcUrl = finalUrl
        // this.srcUrl = 'index.html'
        // console.log('final htmlentry url ', finalUrl)
        // this.addEntryToDocument()
      } else if (this.$xp.device.platform === 'android') {
        this.$xp.device.getRemappedUrl(currentUrl).then((remappedUrl) => {
          this.srcUrl = remappedUrl
          this.addEntryToDocument()
        })
      }
    }
    // this.iframeElement.classList.add('c-html-entry__iframe')
    // console.log('this.srcUrl', this.srcUrl)
    // // const decodedUri = decodeURIComponent(this.srcUrl)
    // // console.log('decoded', decodedUri)
    // const splitted = this.srcUrl.split('xpedeo')
    // console.log('splitted', splitted)
    // const finalUrl = 'http://localhost:6363/xpedeo' + splitted[1]
    // // this.iframeElement.src = finalUrl
    // console.log('final htmlentry url ', finalUrl)

    // this.$el.appendChild(this.iframeElement)
  },
  beforeDestroy () {
    console.log('before destroy htmlentry')
    window.removeEventListener('message', this.onMessage, false)
    if (this.$xp.device.platform === 'ios' && this.localIos) {
      const headElement = document.getElementsByTagName('head')[0]
      const baseElement = document.getElementsByTagName('base')[0]
      headElement.removeChild(baseElement)
      window.XpedeoPreloader.resetGCDBaseGetHandler(
        function () {
        // eslint-disable-next-line no-undef
          console.log('success calling resetGCDBaseGetHandler')
        },
        (errMsg) => {
        // eslint-disable-next-line no-undef
          console.log('error calling resetGCDBaseGetHandler', errMsg)
        })
    }
  },
  methods: {
    getRemappedIosUrl (url) {
      return new Promise(function (resolve, reject) {
        if (window.XpedeoPreloader) {
          window.XpedeoPreloader.getRemappedUrl(
            url,
            (iosUrl) => {
              console.log('ios url', iosUrl)
              resolve(iosUrl)
            },
            (errMsg) => {
              // console.log(errMsg)
              reject(new Error(errMsg))
            })
        } else {
          resolve(url)
        }
      })
    },
    addEntryToDocument () {
    //   const baseHref = 'http://localhost:6363/xpedeo/Binaries/B2578/'
      if (this.localIos) {
        window.XpedeoPreloader.setHtmlEntryGetHandler(
        // this.entry && this.entryData.Content.BinaryID ? [this.entryData.Content.BinaryID] : [],
          this.entryPath && this.entryPath ? [this.entryPath] : [],
          function () {
          // eslint-disable-next-line no-undef
            console.log('success calling sethtmlentrygethandler')
          },
          (errMsg) => {
          // eslint-disable-next-line no-undef
            console.log('error calling sethtmlentrygethandler', errMsg)
          })
      }
      this.setUpIframe()
    // region
    //   const baseElement = document.createElement('base')
    //   //   baseElement.href = 'http://localhost:6363/xpedeo/Binaries/B2578/'
    //   baseElement.href = 'http://localhost:6363/htmlentry/'
    //   //   baseElement.href = 'http://htmlentry:2578/xpedeo/Binaries/B2578/'
    //   //   baseElement.href = '/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/' // app://local/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/
    //   //   baseElement.href = 'http://htmlentry:2578/' // app://local/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/
    //   const headElement = document.getElementsByTagName('head')[0]
    //   headElement.appendChild(baseElement)
    //   this.iframeElement = document.createElement('iframe')
    //   this.iframeElement.classList.add('c-html-entry__iframe')
    //   this.iframeElement.src = this.srcUrl
    //   this.$el.appendChild(this.iframeElement)
    },
    setUpIframe () {
      if (this.$xp.device.platform === 'ios') {
        if (this.localIos) {
          const baseElement = document.createElement('base')
          //   baseElement.href = 'http://localhost:6363/xpedeo/Binaries/B2578/'
          //   baseElement.href = 'http://localhost:6363/htmlentry/'
          baseElement.href = 'http://localhost:6363/B' + this.entryData.Content.BinaryID + '/'
          //   baseElement.href = 'http://htmlentry:2578/xpedeo/Binaries/B2578/'
          //   baseElement.href = '/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/' // app://local/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/
          //   baseElement.href = 'http://htmlentry:2578/' // app://local/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/
          const headElement = document.getElementsByTagName('head')[0]
          headElement.appendChild(baseElement)
        }
        // else {
        //   const baseElement = document.createElement('base')
        //   //   baseElement.href = 'http://localhost:6363/xpedeo/Binaries/B2578/'
        //   //   baseElement.href = 'http://localhost:6363/htmlentry/'
        //   // baseElement.href = 'http://localhost:6363/B' + this.entryData.Content.BinaryID + '/'
        //   baseElement.href = this.srcUrl
        //   //   baseElement.href = 'http://htmlentry:2578/xpedeo/Binaries/B2578/'
        //   //   baseElement.href = '/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/' // app://local/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/
        //   //   baseElement.href = 'http://htmlentry:2578/' // app://local/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/
        //   const headElement = document.getElementsByTagName('head')[0]
        //   headElement.appendChild(baseElement)
        // }
      }
      //  else {
      //   const baseElement = document.createElement('base')
      //   //   baseElement.href = 'http://localhost:6363/xpedeo/Binaries/B2578/'
      //   //   baseElement.href = 'http://localhost:6363/htmlentry/'
      //   // baseElement.href = 'http://localhost:6363/B' + this.entryData.Content.BinaryID + '/'
      //   baseElement.href = this.entryPath
      //   //   baseElement.href = 'http://htmlentry:2578/xpedeo/Binaries/B2578/'
      //   //   baseElement.href = '/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/' // app://local/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/
      //   //   baseElement.href = 'http://htmlentry:2578/' // app://local/_https_proxy_redaktion-mediaguide.nlmh.de/xpedeo/Binaries/B2578/
      //   const headElement = document.getElementsByTagName('head')[0]
      //   headElement.appendChild(baseElement)
      // }
      this.iframeElement = document.createElement('iframe')
      this.iframeElement.classList.add('c-html-entry__iframe')
      this.iframeElement.src = this.srcUrl
      this.$el.appendChild(this.iframeElement)
    },
    onMessage (e) {
      let method = ''
      if (typeof e.data === 'string') {
        method = e.data
      } else if (typeof e.data === 'object') {
        method = e.data.method
      } else {
        throw Error('event data must be a string or an object contains method property as string')
      }
      console.log('HtmlEntry.onMessage:', e.data, ', method', method)
      switch (method) {
        case 'touchstart':
        case 'pointerdown':
          this.onTouchStart()
          break
        case 'touchend':
        case 'pointerup':
          this.onTouchEnd()
          break
        case 'back':
          this.$xp.history.back()
          break
        case 'showPage':
          this.$router.push('/page/' + e.data.pageID)
          break
        case 'openLink':
          handleExternalUrl(e.data.href)
          break
      }
    },
    onTouchStart () {
      // console.log('onTouchstart html entry')
      const e = new Event('pointerdown', { bubbles: true })
      this.$el.dispatchEvent(e)
    },
    onTouchEnd () {
      // console.log('onTouchEnd html entry')
      const e = new Event('pointerup', { bubbles: true })
      this.$el.dispatchEvent(e)
    }
  }
}
</script>
